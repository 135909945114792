import React from "react";

import { Link } from 'react-router-dom'

import logoFooter from "../../Assets/Images/logoMenu2.webp";

import style from "./style.module.scss";

class Footer extends React.Component {
  render() {
    return (
      <div className="container">
        <div className={style.containerFooter}>
          <div>
            <a
              href="https://www.sbt.com.br/politica-de-privacidade"
              target="_blanck"
              className={style.terms}
            >
              Política de Privacidade
            </a>
            <br />
            <Link to="/term" className={style.terms}>
              Termos de Aceite
            </Link>
            <br />
            <a href="https://www.sbt.com.br/institucional#quem-somos" target="_blanck" className={style.terms}>
              Sobre o SBT
            </a>
            <br />
            <Link to="/contact" className={style.terms}>
              Contate-nos
            </Link>
            <br />
            <p className={style.terms}>
              Copyright © {new Date().getFullYear()} SBT Todos os direitos
              reservados.
            </p>
          </div>
          <img src={logoFooter} alt="Logo footer" className={style.logoFooter} />
        </div>
      </div>
    );
  }
}

export default Footer;
