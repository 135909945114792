import apiClient from "../../Api";

const GetAllRanking = (currentPage, perPage, filter) => {
  return apiClient
    .get("users/ranking", {
      params: {
        search: filter,
        limit: currentPage,
        page: perPage,
      },
    })
    .then(({ data }) => {
 
      return data;
    })
    .catch(() => {
      return [];
    });
};

const GetRankingWekly = (currentPage, perPage) => {
  return apiClient
    .get("users/ranking/weekly", {
      params: {
        limit: currentPage,
        page: perPage,
      },
    })
    .then(({ data }) => {

      return data;
    })
    .catch(() => {
      return [];
    });
};

export { GetAllRanking, GetRankingWekly };

export default GetAllRanking;
