import React from "react";

import { Link } from "react-router-dom";

import { GetRankingWekly } from "../Services/Requests/Ranking/index";
import sendQuestions from "../Services/Requests/Questions/index";

import countDown from "../Utils/countDown";
import RankingBar from "../Components/RankingBar/index";
import Input from "../Components/Form/Input/index";

import Slider from "react-slick";

import Contador from "../Components/Counter/index";
/* import CardNews from "../Components/CardNews/index"; */
import Loading from "../Components/Loading/index";
import Footer from "../Components/Footer/index";
import Notification from "../Components/Notification/index";

import playStore from "../Assets/Images/playStore.png";
import appleStore from "../Assets/Images/appleStore.png";
import card1 from "../Assets/Images/card1.webp";
import card2 from "../Assets/Images/card2.webp";
import card3 from "../Assets/Images/card3.webp";
import card4 from "../Assets/Images/card4.webp";
import card5 from "../Assets/Images/card5.webp";
import card6 from "../Assets/Images/card6.webp";
import card7 from "../Assets/Images/card7.webp";
import card8 from "../Assets/Images/card8.webp";
import card9 from "../Assets/Images/card9.webp";
import alexa from "../Assets/Images/alexa.webp";
import qrCode from "../Assets/Images/onlink_to_showdom_small.webp";
import silvio from "../Assets/Images/silvio.webp";

import style from "./home.module.scss";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return <div className={`${className} ${style.arrowL}`} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return <div className={`${className} ${style.arrowR}`} onClick={onClick} />;
}

class Home extends React.Component {
  state = {
    notification: false,
    loading: false,
    activeSlide: 0,
    screenResolution: "",
    allRanking: {
      current_page: 5,
      per_page: 1,
    },
    cont: {
      day: "0",
      hour1: "0",
      hour2: "0",
      min1: "0",
      min2: "0",
    },
    form: {
      question: {
        value: "",
      },
      answer1: {
        value: "",
      },
      answer2: {
        value: "",
      },
      answer3: {
        value: "",
      },
      answer4: {
        value: "",
      },
      answer5: {
        value: "",
      },
    },
  };

  async componentDidMount() {
    const { allRanking } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    setInterval(() => {
      const contador = countDown();
      this.setState((prevState) => ({
        ...prevState,
        cont: contador,
      }));
    }, 1000);

    const data = await GetRankingWekly(
      allRanking.current_page,
      allRanking.per_page
    );

    this.setState((prevState) => ({
      ...prevState,
      screenResolution: window.innerWidth,
      allRanking: data,
      loading: false,
    }));
  }

  handleChange = ({ target: { value, name } }) => {
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: {
          ...prevState.form[name],
          value,
        },
      },
    }));
  };

  handleQuestions = async (e) => {
    e.preventDefault();
    const { form } = this.state;
    const data = {
      question: form.question.value,
      answer1: form.answer1.value,
      answer2: form.answer2.value,
      answer3: form.answer3.value,
      answer4: form.answer4.value,
      answer5: form.answer5.value,
    };

    const response = await 200;
    sendQuestions(data);
    console.log(response);
    this.setState((prevState) => ({
      ...prevState,
      notification: response === 200 ? true : false,
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        notification: false,
      }));
    }, 5000);
  };

  render() {
    const {
      activeSlide,
      screenResolution,
      cont,
      allRanking,
      loading,
      notification,
      form,
    } = this.state;

    const { question, answer1, answer2, answer3, answer4, answer5 } = form;

    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      speed: 500,
      centerPadding: "0",
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    /*     const settingsNews = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }; */
    return (
      <>
        {notification && (
          <Notification
            title="Sucesso!"
            description="Mensagem enviada com sucesso"
            typeNotification="success"
          />
        )}
        <div className="bg-image-silvio">
          <div className={style.containerTitleHome}>
            <h1 className="text-white text-center text-semibold uppercase pt-separate-double mb-not-separate mt-not-separate text-shadow-10">
              O game mais famoso do Brasil esta disputado!
            </h1>
          </div>
          <div className="container pt-separate-base pb-separate-base" >
            <div style={{ marginTop: 0 }} className={`${style.containerStore}`}>
              <div className={style.store}>
                {screenResolution < 1024 ? (
                  <>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.sbt.showdomilhao"
                      target="_blanck"
                    >
                      <img src={playStore} alt="Loja play store" />
                    </a>
                    <a
                      href="https://apps.apple.com/br/app/show-do-milh%C3%A3o-oficial/id1225691391"
                      target="_blanck"
                    >
                      <img src={appleStore} alt="Loja apple store" />
                    </a>{" "}
                  </>
                ) : (
                  <>
                    <img
                      style={{ width: "200px" }}
                      src={qrCode}
                      alt="QR Code"
                      border="0"
                    />
                  </>
                )}
              </div>
              <h2 style={{ marginTop: 40 }} className="text-white text-center text-semibold text-shadow-10">
                Baixe agora mesmo para se divertir!
              </h2>
            </div>
          </div>
        {/*  <div className={`bg-white ${style.containerStore}`}>
          <div className="container">
            <h1 className="text-shadow-2 uppercase text-primary text-center text-semibold">
              Faça o download agora mesmo!
            </h1>
            <div className={style.store}>
              {screenResolution < 1024 ? (
                <>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.sbt.showdomilhao"
                    target="_blanck"
                  >
                    <img src={playStore} alt="Loja play store" />
                  </a>
                  <a
                    href="https://apps.apple.com/br/app/show-do-milh%C3%A3o-oficial/id1225691391"
                    target="_blanck"
                  >
                    <img src={appleStore} alt="Loja apple store" />
                  </a>{" "}
                </>
              ) : (
                <>
                  <img src={qrCode} alt="QR Code" border="0" />
                </>
              )}
            </div>
          </div>
        </div> */}
        </div>
        <div className="container pt-separate-medium ">
          <h2 className="text-white text-center text-semibold text-shadow-10">
            Estes são os melhores jogadores na atual rodada do ranking
          </h2>
          <div>
            <div className={style.titleBoxRanking}>
              <h2>Ranking semanal</h2>
            </div>
            <div className={style.boxRanking}>
              {loading ? <Loading /> : <RankingBar data={allRanking.data} />}
            </div>
            <div className={style.containerViewMore}>
              <div className={`${style.viewMore}`}>
                <Link to="/ranking" className="btn-secondary">
                  Ver mais
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className={style.containerCount}>
            <div className="d-flex j-center">
              <div>
                <h2 className="text-shadow-10 text-white text-center pb-small-separate pt-separate-medium">
                  O ranking irá reiniciar em:
                </h2>
                <Contador
                  day1="0"
                  day2={cont.day}
                  hour1={cont.hour1}
                  hour2={cont.hour2}
                  min1={cont.min1}
                  min2={cont.min2}
                />
                <p
                  className={`text-shadow-10 ${style.textCount} pt-small-separate`}
                >
                  O Ranking semanal é reiniciado todas as terças-feiras <br />
                  as 00:00:00 horas *(Horário de Brasília)*
                </p>
              </div>
            </div>
            <div className={style.containerImage}>
              <img
                src={silvio}
                alt="Silvio Santos"
                className="pt-small-separate"
              />
            </div>
          </div>
        </div>

        <div className={`bg-gray ${style.containerStore}`}>
          <div className="container">
            <h1 className="uppercase text-primary text-center text-semibold">
              Também estamos na Alexa da Amazon!
            </h1>
            <div className={`mt-small-separate ${style.store}`}>
              <img src={alexa} alt="Alexa Amazon" />
            </div>
            <div className={style.containerLink}>
              <a
                href="https://www.bing.com/search?q=show+do+milhao+alexa&cvid=1e1e6760bb1f4c65a824cf276e47ed43&aqs=edge.0.69i59j69i60j69i57j69i60j69i61l2j1001i1i11002.1728j0j1&pglt=43&FORM=ANNAB1&PC=U531"
                target="blanck"
              >
                Saiba como jogar utilizando Alexa clicando aqui.
              </a>
            </div>
          </div>
        </div>
        <div className="container">
          <h1 className="text-shadow-5 text-white text-center text-semibold uppercase pt-separate-double pb-small-separate">
            Se aventure nos novos modos de jogar
          </h1>
          <div className={style.containerCarrosel}>
            <Slider {...settings}>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 0 && style.active
                }`}
              >
                <img src={card1} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 1 && style.active
                }`}
              >
                <img src={card2} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 2 && style.active
                }`}
              >
                <img src={card3} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 3 && style.active
                }`}
              >
                <img src={card4} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 4 && style.active
                }`}
              >
                <img src={card5} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 5 && style.active
                }`}
              >
                <img src={card6} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 6 && style.active
                }`}
              >
                <img src={card7} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 7 && style.active
                }`}
              >
                <img src={card8} alt="Card modo de jogar" />
              </div>
              <div
                className={`${style.imageCard} ${
                  activeSlide === 8 && style.active
                }`}
              >
                <img src={card9} alt="Card modo de jogar" />
              </div>
            </Slider>
          </div>
        </div>
        <div className={`bg-white ${style.containerStore}`}>
          <div className="container">
            <h1 className="uppercase text-primary text-center text-semibold pt-separate-medium">
              QUER VER SUA PERGUNTA DENTRO DO GAME?
            </h1>
            <div className="pl-5 pr-5">
              <p className=" text-primary text-justify pb-small-separate">
                O game Show do Milhão é colaborativo com a comunidade, sendo
                assim, você pode nos enviar suas perguntas preenchendo o
                formulário abaixo!
              </p>
            </div>
            <div className={style.containerQuestions}>
              <form
                onSubmit={(e) => this.handleQuestions(e)}
                className={style.formContact}
              >
                <div className="mb-small-separate color-primary">
                  <Input
                    label="Pergunta"
                    type="question"
                    required
                    className={style.input}
                    value={question.value}
                    onChange={this.handleChange}
                    id="question"
                    name="question"
                  />
                </div>
                <div className="">
                  <Input
                    label="Resposta 1 (falsa)"
                    type="answer1"
                    required
                    value={answer1.value}
                    onChange={this.handleChange}
                    id="answer1"
                    name="answer1"
                  />
                </div>
                <div className="">
                  <Input
                    label="Resposta 2 (falsa)"
                    type="answer2"
                    required
                    value={answer2.value}
                    onChange={this.handleChange}
                    id="answer2"
                    name="answer2"
                  />
                </div>
                <div className="">
                  <Input
                    label="Resposta 3 (falsa)"
                    type="answer3"
                    required
                    value={answer3.value}
                    onChange={this.handleChange}
                    id="answer3"
                    name="answer3"
                  />
                </div>
                <div className="">
                  <Input
                    label="Resposta 4 (falsa)"
                    type="answer4"
                    required
                    value={answer4.value}
                    onChange={this.handleChange}
                    id="answer4"
                    name="answer4"
                  />
                </div>
                <div className="">
                  <Input
                    label="Resposta 5 (Verdadeira)"
                    type="answer5"
                    required
                    value={answer5.value}
                    onChange={this.handleChange}
                    id="answer5"
                    name="answer5"
                  />
                </div>
                <div className="d-flex j-center pb-separate-medium pt-small-separate">
                  <button className="btn-primary bg-primary text-white ">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container">
          {/* <h1 className="text-shadow-5 text-white text-center text-semibold uppercase pt-separate-medium pb-small-separate">
              Siga as novidades do game
            </h1>
            <div className={style.containerCarroselNews}>
              <Slider {...settingsNews}>
                <div>
                  <CardNews
                    className={style.separateNews}
                    date="16/01/2021"
                    text="Caso o jogador não responda a pergunta antes do tempo acabar ele fica com a mesma pontuação referente ao botão parar."
                  />
                </div>
                <div>
                  <CardNews
                    className={style.separateNews}
                    date="16/01/2021"
                    text="Caso o jogador não responda a pergunta antes do tempo acabar ele fica com a mesma pontuação referente ao botão parar."
                  />
                </div>
                <div>
                  <CardNews
                    className={style.separateNews}
                    date="16/01/2021"
                    text="Caso o jogador não responda a pergunta antes do tempo acabar ele fica com a mesma pontuação referente ao botão parar."
                  />
                </div>
                <div>
                  <CardNews
                    className={style.separateNews}
                    date="16/01/2021"
                    text="Caso o jogador não responda a pergunta antes do tempo acabar ele fica com a mesma pontuação referente ao botão parar."
                  />
                </div>
              </Slider>
            </div> */}
          <Footer />
        </div>
      </>
    );
  }
}

export default Home;
