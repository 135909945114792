import React from "react";

import style from "./style.module.scss";

class InputSearch extends React.Component {
  render() {
    const { placeholder, onChange, name } = this.props;
    return (
      <input
        type="text"
        name={name}
        id="search"
        placeholder={placeholder}
        onChange={onChange}
        className={style.inputSearch}
       
      />
    );
  }
}

export default InputSearch;
