import React from "react";

import style from "./style.module.scss";

class Input extends React.Component {
  render() {
    const {
      value,
      name,
      className,
      required,
      label,
      footer,
      type,
      onChange,
      id,
    } = this.props;
    return (
      <>
        <div className={style.labelFloat}>
          <label>{label}</label>
          <input
            required={required}
            type={type}
            name={name}
            value={value}
            placeholder=" "
            className={`${className}`}
            onChange={onChange}
            id={id}
          />
        </div>
        <span className={style.footerInput}>{footer}</span>
      </>
    );
  }
}

export default Input;
