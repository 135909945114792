import React from "react";

import style from "./style.module.scss";
import primeiro from "../../Assets/Images/primeiro.webp";
import segundo from "../../Assets/Images/segundo.webp";
import terceiro from "../../Assets/Images/terceiro.webp";

class RankingBar extends React.Component {
  render() {
    const { data, spacing } = this.props;
    return (
      <div className={style.table}>
        {data?.length > 0 ? (
          <>
            {data?.map((item, index) => (
              <div key={index} className={style.containerCard} style={{marginBottom: spacing ? spacing : "0"}}>
                <div className={style.containerNumber}>
                  <p className={style.number}>{item.position}</p>
                  {item.position === 1 && (
                    <img
                      src={primeiro}
                      alt="Primeiro"
                      className={style.imgPosition}
                    />
                  )}
                  {item.position === 2 && (
                    <img
                      src={segundo}
                      alt="Segundo"
                      className={style.imgPosition}
                    />
                  )}
                  {item.position === 3 && (
                    <img
                      src={terceiro}
                      alt="Terceiro"
                      className={style.imgPosition}
                    />
                  )}
                </div>
                <div className={style.containerName}>
                  <p className={style.name}>{item.name}</p>
                </div>
                <div className={style.containerMoney}>
                  <p className={style.money}>{item.points.toLocaleString('pt-BR')}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className={style.containerCard}>
            <div className={style.containerNotInformation}>
              <p className={style.notInformation}>
                Ops! Não encontramos nenhuma informação!
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RankingBar;
