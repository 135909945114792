import React from "react";

import GetAllRanking from "../../Services/Requests/Ranking/index";

import RankingBar from "../../Components/RankingBar/index";
import InputSearch from "../../Components/InputSearch/index";
import Loading from "../../Components/Loading/index";
import Footer from "../../Components/Footer/index";

import style from "./style.module.scss";

class Ranking extends React.Component {
  state = {
    loading: false,
    allUsers: [],
    filter: {
      value: "",
    },
  };

  async componentDidMount() {
    const { filter } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const data = await GetAllRanking(50, 1, filter.value);

    this.setState((prevState) => ({
      ...prevState,
      allUsers: data,
      loading: false,
    }));
  }

  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });

    if (this.timeout) clearInterval(this.timeout);
    this.timeout = setTimeout(() => {
      this.onFilter();
    }, 500);
  };

  onFilter = async () => {
    const { filter } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await GetAllRanking(50, 1, filter);

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
      allUsers: response,
    }));
  };

  render() {
    const { allUsers, loading } = this.state;
    return (
      <>
        <div className="pb-separate-base">
          <div className="container">
            <h1 className="text-shadow-5 text-white text-center text-semibold uppercase pt-separate-double mt-not-separate">
              Ranking
            </h1>
            <div className={style.search}>
              <InputSearch
                name="filter"
                onChange={this.handleChange}
                placeholder="Pesquisar jogadores"
              />
            </div>
            <div className={`mb-separate-base ${style.containerRanking}`}>
              {loading ? (
                <div className="mt-separate-medium mb-separate-medium">
                  <Loading />
                </div>
              ) : (
                <RankingBar data={allUsers.data} spacing="10px" />
              )}
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Ranking;
