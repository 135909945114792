import React from "react";
import { Route, Switch } from "react-router-dom";
import useGaTracker from "../useGaTracker";

import Home from "../Pages/index";
import Ranking from "../Pages/Ranking/index";
import Faq from "../Pages/Faq/index";
import Contact from "../Pages/Contact/index";
import Term from "../Pages/Term/index";

const Routes = () => {
  useGaTracker();
  return (
    <main>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/ranking" component={Ranking} />
        <Route path="/faq" component={Faq} />
        <Route path="/contact" component={Contact} />
        <Route path="/term" component={Term} />
      </Switch>
    </main>
  );
};

export default Routes;
