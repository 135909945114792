const contador = () => {
  let subCount = 0;
  switch (new Date().getDay()) {
    case 3:
      subCount = 1;
      break;
    case 4:
      subCount = 2;
      break;
    case 5:
      subCount = 3;
      break;
    case 6:
      subCount = 4;
      break;
    case 0:
      subCount = 5;
      break;
    case 1:
      subCount = 6;
      break;
    default:
      subCount = 0;
  }

  let countDay = 7 - subCount;

  let now = new Date();

  let year = new Date().getFullYear();
  let month;
  let day = new Date();
  day.setDate(day.getDate() + countDay);

  if (day.getDate() - now.getDate() < 0) {
    month = new Date().getMonth() + 1;
  } else {
    month = new Date().getMonth();
  }

  let eventDate = new Date(year, month, day.getDate());
  let currentTime = now.getTime();

  let eventTime = eventDate.getTime();

  let remTime = eventTime - currentTime;

  let s = Math.floor(remTime / 1000);
  let m = Math.floor(s / 60);
  let h = Math.floor(m / 60);
  let d = Math.floor(h / 24);

  h %= 24;
  m %= 60;
  s %= 60;

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  let cont = {
    day: d.toString().substr(0, 1),
    hour1: h.toString().substr(0, 1),
    hour2: h.toString().substr(1, 1),
    min1: m.toString().substr(0, 1),
    min2: m.toString().substr(1, 1),
  };

  return cont;
};

export default contador;
