import React from "react";

import sendContact from "../../Services/Requests/Contact/index";

import toBase64 from "../../Utils/base64";

import Input from "../../Components/Form/FloatLabel/Input/index";
import TextArea from "../../Components/Form/FloatLabel/TextArea/index";
import InputUpload from "../../Components/Form/FloatLabel/InputUpload/index";
import FileList from "../../Components/FileList/index";
import Footer from "../../Components/Footer/index";
import Notification from "../../Components/Notification/index";

import style from "./style.module.scss";

class Contact extends React.Component {
  state = {
    notification: false,
    uploadedFiles: [],
    form: {
      email: {
        value: "",
      },
      emailGame: {
        value: "",
      },
      modelCell: {
        value: "",
      },
      versionApp: {
        value: "",
      },
      subjectMatter: {
        value: "",
      },
      description: {
        value: "",
      },
    },
  };

  handleChange = ({ target: { value, name } }) => {
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: {
          ...prevState.form[name],
          value,
        },
      },
    }));
  };

  handleUpload = async (files) => {
    const arr = [
      {
        file: await toBase64(files[0]),
        file_name: files[0].name,
        mime: files[0].type,
        preview: URL.createObjectURL(files[0]),
      },
    ];

    this.setState((prevState) => ({
      ...prevState,
      uploadedFiles: this.state.uploadedFiles.concat(arr),
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { form, uploadedFiles } = this.state;
    const data = {
      email: form.email.value,
      emailGame: form.emailGame.value,
      modelCell: form.modelCell.value,
      versionApp: form.versionApp.value,
      subjectMatter: form.subjectMatter.value,
      description: form.description.value,
      attachments: uploadedFiles,
    };
    const response = await sendContact(data);
    this.setState((prevState) => ({
      ...prevState,
      notification: response === 200 ? true : false,
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
        notification: false,
      }));
    }, 5000);
  };

  render() {
    const { form, uploadedFiles, notification } = this.state;
    const {
      email,
      emailGame,
      modelCell,
      versionApp,
      subjectMatter,
      description,
    } = form;
    return (
      <>
        {notification && (
          <Notification
            title="Sucesso!"
            description="Mensagem enviada com sucesso"
            typeNotification="success"
          />
        )}
        <div>
          <div className="container pt-separate-medium">
            <div className={style.containerContact}>
              <h1 className="text-white text-center text-semibold uppercase pt-separate-medium mb-not-separate mt-not-separate text-shadow-10">
                Fale conosco
              </h1>
              <p className="text-white text-normal text-center">
                Precisa tirar alguma dúvida? Quer enviar enviar alguma sugestão?
                Está com algum problema no game? Preencha o formulário abaixo e
                fale conosco.
              </p>
              <form
                onSubmit={(e) => this.handleSubmit(e)}
                className={style.formContact}
              >
                <div className="mb-small-separate">
                  <Input
                    label="Endereço de e-mail"
                    type="email"
                    required
                    value={email.value}
                    onChange={this.handleChange}
                    id="email"
                    name="email"
                  />
                </div>
                <div className="mb-small-separate">
                  <Input
                    label="Endereço de e-mail cadastrado no jogo"
                    type="email"
                    required
                    value={emailGame.value}
                    onChange={this.handleChange}
                    id="emailGame"
                    name="emailGame"
                  />
                </div>
                <div className="mb-small-separate">
                  <Input
                    label="Modelo do seu celular"
                    type="text"
                    required
                    value={modelCell.value}
                    onChange={this.handleChange}
                    name="modelCell"
                  />
                </div>
                <div className={style.separeteInput}>
                  <Input
                    label="Versão do aplicativo"
                    type="text"
                    footer="(a versão do app está vísivel no menu inferior escrito em branco com letras pequenas)"
                    value={versionApp.value}
                    onChange={this.handleChange}
                    name="versionApp"
                  />
                </div>
                <div className="mb-small-separate">
                  <Input
                    label="Assunto"
                    type="text"
                    value={subjectMatter.value}
                    onChange={this.handleChange}
                    name="subjectMatter"
                  />
                </div>
                <div className="mb-small-separate">
                  <TextArea
                    label="Descrição do contato"
                    rows="8"
                    value={description.value}
                    onChange={this.handleChange}
                    name="description"
                  />
                </div>
                <div className="mb-separate-medium">
                  <InputUpload
                    label="Anexo"
                    onUpload={this.handleUpload}
                    name="file"
                  />
                  {!!uploadedFiles.length && <FileList files={uploadedFiles} />}
                </div>
                <div className="d-flex j-center">
                  <button className="btn-primary">Enviar</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Contact;
