import React from "react";
import DropZone from "react-dropzone";
import style from "./style.module.scss";

class Input extends React.Component {
  render() {
    const { onUpload } = this.props;
    return (
      <>
        <label className={style.label}>Anexo</label>
        <DropZone accept="image/*,.doc,.docx,.pdf" onDropAccepted={onUpload}>
          {({ getRootProps, getInputProps }) => (
            <div className={style.dropContainer} {...getRootProps()}>
              <input {...getInputProps()} />
              <span className={style.textUpload}>
                Adicione ou solte arquivos aqui
              </span>
            </div>
          )}
        </DropZone>
      </>
    );
  }
}

export default Input;
