import React from "react";

import Collapsed from "../../Components/Collapsed/index";
import Footer from "../../Components/Footer/index";

import ImageCard1 from "../../Assets/Images/image-8.webp";
import ImageCard2 from "../../Assets/Images/image-10.webp";
import tablePoints from "../../Assets/Images/tabela-pontos.webp";

import style from "./style.module.scss";

const data = [
  {
    title: "Como salvo meu progresso no jogo?",
    text: "O progresso do jogo fica vinculado a sua conta para isto basta realizar o cadastro e conectar-se.",
  },
  {
    title: "Posso transferir meu progresso do jogo para um novo dispositivo?",
    text: "Para transferir o progresso é só ir em conectar e realizar o login com o e-mail e senha cadastrados.",
  },
  {
    title: "O jogo está congelando ou travando. O que devo fazer?",
    text: "Confira se o aparelho tem espaço de armazenamento suficiente e libere memória para que o jogo funcione da melhor maneira possível.",
  },
  {
    title: "Quantas perguntas tem em cada partida?",
    text: "Cada partida é composta por 16 perguntas separadas em 4 níveis: nível 1, nível 2, nível 3 e tudo ou nada onde as  etapas fácil, médio e difícil corresponde a 4 perguntas cada e a etapa tudo ou nada corresponde a pergunta de 1 milhão.",
  },
  {
    title: "Quanto tempo tem cada pergunta?",
    text: "Cada pergunta tem o intervalo de 30s para ser respondida.",
  },
  {
    title: "Qual a diferença do ranking geral para o ranking semanal?",
    text: "O ranking geral corresponde ao seu progresso no jogo em cada temporada, já o ranking semanal é o seu progresso durante a semana onde é reiniciado toda terça as 00:00 hrs (horario de brasilia).",
  },
  {
    title: "Quantas ajudas o jogo possui?",
    text: "O jogo possui 4 ajudas sendo elas: cartas, placas, convidados e pular.",
  },
  {
    title: "Como desligo o som do jogo?",
    text: "Para desativar é necessário ir em configurações, onde é possível ativar/desativar som do jogo(falas do Silvio) e musica do jogo.",
  },
  {
    title: "Como personalizo meu avatar?",
    text: "Para personalização é só ir em perfil e avatar, lá é possível escolher cor do cabelo, pele e camiseta.",
  },
  {
    title: "Como jogo por temas?",
    text: "Para escolher o tema basta ir em perfil, clicar na opção temas e escolher os temas de sua preferencia, é possível jogar com perguntas de ate 3 temas diferentes.",
  },
  {
    title: "Quantos pontos ganho quando o tempo é esgotado?",
    text: "Quando o tempo da questão é finalizado a pontuação da partida fica a mesma de quando o jogo é parado (botão parar).",
  },
  {
    title: "Quantos pulos tenho em cada  partida do jogo (versão gratuita)?",
    text: "Para cada partida são disponibilizado 3 pulos.",
  },
  {
    title: "Posso resgatar o valor do jogo em dinheiro?",
    text: "Não, o valor em dinheiro no jogo corresponde a pontuação e moeda do jogo.",
  },
  {
    title: "Posso alterar o meu nome no jogo?",
    text: "Na atual versão não é possível alterar nome de usuário.",
  },
  {
    title: "Como é calculada a pontuação do jogo ?",
    text: "A conversão dos pontos é feita de acordo a pirâmide abaixo.",
    img: tablePoints,
  },
];

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="pb-separate-medium">
          <div className="container">
            <h1 className="text-shadow-5 text-white text-center text-semibold uppercase pt-separate-double mt-not-separate">
              Conheça as regras do game
            </h1>
            <div className="pt-separate-medium">
              <Collapsed data={data} />
            </div>
            <div className="pt-separate-medium">
              <div
                className={`row ${style.containerReverse} pb-separate-medium`}
              >
                <div className="md-5 xs-12">
                  <div className={style.cardImage}>
                    <img
                      src={ImageCard1}
                      alt="Como o jogo funciona"
                      className={`fl ${style.imageCard}`}
                    />
                  </div>
                </div>
                <div className="md-7 xs-12">
                  <h2
                    className="text-white text-semibold"
                    style={{ marginTop: "-15px" }}
                  >
                    Como o jogo funciona?
                  </h2>
                  <p className="text-white text-normal">
                    Cada partida é composta por 16 perguntas, em cada pergunta,
                    o jogador terá 30 segundos para escolher a alternativa
                    correta. As perguntas estão classificadas em quatro níveis
                    de dificuldade.
                  </p>
                  <p className="text-white text-normal">
                    A cada pergunta respondida corretamente, o jogador obtém os
                    pontos pergunta. Ao parar a partida ou erre a pergunta ele
                    fica com a pontuação referente a ultima pergunta. *O valor
                    da pergunta é convertida em pontos conforme imagem
                    apresentada na primeira tela.
                  </p>
                  <p className="text-white text-normal">
                    Caso o jogador não responda a pergunta antes do tempo acabar
                    ele fica com a mesma pontuação referente ao botão "parar"
                  </p>
                </div>
              </div>
              <div className="row pt-separate-medium">
                <div className="md-7 xs-12">
                  <h2
                    className="text-white text-semibold"
                    style={{ marginTop: "-15px" }}
                  >
                    Como o jogo funciona?
                  </h2>
                  <p className="text-white text-normal">
                    Cada partida é composta por 16 perguntas, em cada pergunta,
                    o jogador terá 30 segundos para escolher a alternativa
                    correta. As perguntas estão classificadas em quatro níveis
                    de dificuldade.
                  </p>
                  <p className="text-white text-normal">
                    A cada pergunta respondida corretamente, o jogador obtém os
                    pontos pergunta. Ao parar a partida ou erre a pergunta ele
                    fica com a pontuação referente a ultima pergunta. *O valor
                    da pergunta é convertida em pontos conforme imagem
                    apresentada na primeira tela.
                  </p>
                  <p className="text-white text-normal">
                    Caso o jogador não responda a pergunta antes do tempo acabar
                    ele fica com a mesma pontuação referente ao botão "parar"
                  </p>
                </div>
                <div className="md-5 xs-12">
                  <div className={style.cardImage}>
                    <img
                      src={ImageCard2}
                      alt="Como o jogo funciona"
                      className={`fr ${style.imageCard}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Faq;
