import React from "react";

import style from "./style.module.scss";

class Counter extends React.Component {
  render() {
    const { day1, day2, hour1, hour2, min1, min2 } = this.props;
    return (
      <div className={style.containerCounter}>
        <div className={style.containerTime}>
          <div className={style.containerNumbers}>
            <div className={style.containerNumber}>
              <p>{day1}</p>
            </div>
            <div className={style.containerNumber}>
              <p>{day2}</p>
            </div>
          </div>
          <p>Dias</p>
        </div>
        <div className={style.containerTime}>
          <div className={style.containerNumbers}>
            <div className={style.containerNumber}>
              <p>{hour1}</p>
            </div>
            <div className={style.containerNumber}>
              <p>{hour2}</p>
            </div>
          </div>
          <p>Horas</p>
        </div>
        <div className={style.containerTime}>
          <div className={style.containerNumbers}>
            <div className={style.containerNumber}>
              <p>{min1}</p>
            </div>
            <div className={style.containerNumber}>
              <p>{min2}</p>
            </div>
          </div>
          <p>Minutos</p>
        </div>
      </div>
    );
  }
}

export default Counter;
