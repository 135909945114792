import React from "react";

/* import style from "./style.module.scss"; */

class Term extends React.Component {
  render() {
    return (
      <div className="container pt-separate-double">
        <h2 className="text-white text-center text-semibold">
          Termo de aceite
        </h2>
        <div>
          <p className="text-white text-justify">
            “Declaro que li o presente termo, onde consinto e concordo que a
            emissora TVSBT Canal 4 de São Paulo S/A, CNPJ nº 45.039.237/0001-14,
            doravante denominada Controladora, tome decisões e realize o
            tratamento de meus dados pessoais informados nesse cadastro com a
            finalidade de obter a melhor experiência neste jogo e com a emissora
            SBT, envolvendo operações como as que se referem a coleta, produção,
            recepção, classificação, utilização, acesso, reprodução,
            transmissão, distribuição, processamento, arquivamento,
            armazenamento, eliminação, avaliação ou controle da informação,
            modificação, comunicação, transferência, difusão ou extração
            alinhadas com esta pesquisa. Seguindo os requisitos de segurança
            conforme rege a Lei 13.709/2018. Podendo ser revogado este termo a
            qualquer momento pelo Titular, mediante a requisição formal através
            do e-mail dpo@sbt.com.br.
          </p>
          <p className="text-white text-justify">
            Ao aceitar este termo, você também concorda com a nossa Política de
            Privacidade”.
          </p>
        </div>
      </div>
    );
  }
}

export default Term;
