import apiClient from "../../Api";

const sendQuestions = (data) => {
  return apiClient
    .post(
      "https://t4ytqze792.execute-api.us-east-1.amazonaws.com/dev/questions",
      data
    )
    .then(({ status }) => {
      if ((status) => 200 <= 400) {
        return status;
      }
      return [];
    })
    .catch(() => {
      return [];
    });
};

export default sendQuestions;
