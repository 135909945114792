import React from "react";

import style from "./style.module.scss";

class TextArea extends React.Component {
  render() {
    const {
      value,
      name,
      className,
      required,
      label,
      footer,
      rows,
      cols,
      onChange,
      id
    } = this.props;
    return (
      <>
        <div className={style.labelFloat}>
          <textarea
            className={`${className}`}
            required={required}
            placeholder=" "
            value={value}
            name={name}
            rows={rows}
            cols={cols}
            onChange={onChange}
            id={id}
          ></textarea>
          <label>{label}</label>
        </div>
        <span className={style.footerInput}>{footer}</span>
      </>
    );
  }
}

export default TextArea;
