import React from "react";

import style from "./style.module.scss";

class FileList extends React.Component {
  render() {
    const { files } = this.props;
    return (
      <ul className={style.containerFileList}>
        {files?.map((item, index) => (
          <li key={index}>
            <img src={item.preview} alt={item.name} />
            <div>
              <strong>{item.file_name}</strong>
            </div>
          </li>
        ))}
      </ul>
    );
  }
}

export default FileList;
